<template>
    <div id="home" class="home container-fluid">
        <!-- Image main -->
        <section class="bg-image-custom-right" 
            :style="{'background-image' : `url(${img_thank})`, 
                     'height' : `${height_thank}px`, 
                     'min-height' : `100px` }">

            <div class="container h-100">
                <div class="row h-100 d-flex justify-content-center align-items-start">

                    <div class="col-12 mt-custom-text">
                        <div class="mb-3 mb-sm-5">
                            <div style="max-width: 700px;">
                                <span class="hc--title text-white">
                                    ¡<span class="text-warning">Felicidades</span>,  estás a un paso de obtener tu finaciamiento!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Overexposure -->
        <section class="row section--overexposure">
            <div class="col-12">
                
                <div class="container bg-primary-gradient rounded-13">
                    <div class="row d-flex justify-content-center align-items-center text-center pt-4 pb-3 px-0">

                        <div class="col-12 d-flex justify-content-center mb-4 pt-3">
                            <div style="max-width: 800px;">
                                <span class="hc--description poppins-semibold text-white">
                                    Te contactaremos a la brevedad para proporcionarte mayor información y resolver todas tus dudas
                                </span>
                            </div>
                        </div>

                        <div class="col-12 mb-4">
                            <span @click="redirect('home')" class="hc--description-xs text-decoration-underline 
                                poppins-semibold text-warning cursor-pointer">
                                    Volver a calcular
                            </span>
                        </div>

                        <div class="col-12 mb-3">
                            <span class="hc--description-sm poppins-semibold text-white">
                                Guarda este folio de preautorización
                            </span>
                        </div>

                        <div class="col-12 d-flex justify-content-center mb-4">
                            <div style="max-width: 330px; width: 100%">
                                <input :value="get_folio" type="text" name="folio" 
                                    class="input-thm-light poppins-bold text-center full-width" disabled>
                            </div>
                        </div>

                        <div class="col-12 d-flex justify-content-center mb-2">
                            <a href="https://hircasa.com/" target="_blank">
                                <button class="btn btn-warning poppins-semibold rounded-13 text-white">
                                    Conoce más de HIR Casa
                                </button>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="row d-flex justify-center centrarTextoDama">
                    <div class="col-10 mt-4 text-center">
                        <span class="hc--title">
                            ¡Si eres mujer, pregunta a nuestros asesores acerca las promociones exclusivas para ti
                        </span>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

    import _Store from '@/store'
    import { mapGetters } from 'vuex'

    // COMPONENTS
    import Carousel from '../components/Carousel.vue'

    // IMAGES
    import img_thank from '../assets/images/thank_image2.png'
    
    // JQuery
    import $ from 'jquery'


    export default {
        name: 'home',
        components: {
            'hc-carousel': Carousel
        },
        data() {
            return {
                device_width: 0,
                device_height: 0,

                // Image main
                img_thank,
                img_thank_width: 0,
                img_thank_height: 0,
                height_thank: 0,
            }
        },
        computed: {
            ...mapGetters([
                'getFolio'
            ]),

            get_folio() {
                return this.getFolio
            }
        },
        methods: {
            chargeImage() {
                let image_main = new Image()
                image_main.src = img_thank
                this.img_thank_width = (image_main.width > 0) ? image_main.width : 1440
                this.img_thank_height = (image_main.height > 0) ? image_main.height : 631

                // First charge
                // image_main.onload = function() {
                //     alert(this.width + 'x' + this.height)
                // }
            },
            onResize() {
                //console.log('resize ThankYou')
                this.device_width = document.documentElement.clientWidth
                this.device_height = document.documentElement.clientHeight
                // console.log(`[IMAGE] w: ${this.img_thank_width}, h: ${this.img_thank_height}`)

                const h_for_w = this.img_thank_height / this.img_thank_width
                this.height_thank = this.device_width * h_for_w
                // console.log(this.device_width, 'this.device_width')
                // console.log(h_for_w, 'h_for_w')
                // console.log(this.height_thank, 'this.height_thank')
            },
            redirect(route_name) {
                if(this.$route.name !== route_name ) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: route_name
                    })
                }
            }
 
        },
        created() {
            window.fbq('track', 'PageView');
        },

        async mounted() {
            window.addEventListener("resize", this.onResize)
            await this.chargeImage()

            this.onResize()
            // let preauthorization_folio = Math.random().toString(36).slice(2).substring(0,7).toUpperCase()
            // _Store.commit('setFolio', preauthorization_folio)
        },
        async beforeDestroy() {
            window.removeEventListener("resize", this.onResize)
            await _Store.dispatch('deleteFolio')
        }
    }
</script>

<style lang="scss" scoped>
.centrarTextoDama{
    display: flex;
    justify-content: center;
}
    #home {

        .mt-custom-text {
            margin-top: 2rem;
        }
        .section--overexposure {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) {
            .mt-custom-text {
                margin-top: 9rem;
            } 
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) { 
            .mt-custom-text {
                margin-top: 9rem;
            }
            .section--overexposure {
                margin-top: -7rem;
                margin-bottom: 100px;
            }
        }
        @media (min-width: 837px) { 
            .mt-custom-text {
                margin-top: 9rem;
            }
            .section--overexposure {
                margin-top: -9rem;
            }
        }
        // Large devices (desktops, 992px and up)
        @media (min-width: 1000px) {
            .mt-custom-text {
                margin-top: 10rem;
            }
            .section--overexposure {
                margin-top: -12rem;
            }
        }
        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
            .mt-custom-text {
                margin-top: 12rem;
            }
            .section--overexposure {
                margin-top: -14rem;
            }
        }
        @media (min-width: 1400px) {
            .mt-custom-text {
                margin-top: 15rem;
            }
            .section--overexposure {
                margin-top: -17rem;
            }
        }



        

        @media (min-width: 1200px) {
            .mnr-xl-4 {
                margin-right: -4px;
            }
        }


        .card_blur {
            margin-left: -10px;
            margin-top: -20px; 
            width: 310px;
            backdrop-filter: blur(30px);
            mix-blend-mode: normal;
            box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.15);
            border-color: white !important; border-width: medium !important;
            z-index: 2;
        }

        @media (min-width: 576px) {
            .card_blur {
                margin-left: 0px;
            }
        }

        @media (min-width: 768px) {
            .card_blur {
                margin-top: -50px; 
                margin-left: 120px; 
            }
        }
    }
</style>
