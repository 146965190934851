var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home container-fluid",attrs:{"id":"home"}},[_c('section',{staticClass:"bg-image-custom-right",style:({'background-image' : `url(${_vm.img_thank})`, 
                 'height' : `${_vm.height_thank}px`, 
                 'min-height' : `100px` })},[_vm._m(0)]),_c('section',{staticClass:"row section--overexposure"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"container bg-primary-gradient rounded-13"},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center text-center pt-4 pb-3 px-0"},[_vm._m(1),_c('div',{staticClass:"col-12 mb-4"},[_c('span',{staticClass:"hc--description-xs text-decoration-underline poppins-semibold text-warning cursor-pointer",on:{"click":function($event){return _vm.redirect('home')}}},[_vm._v(" Volver a calcular ")])]),_vm._m(2),_c('div',{staticClass:"col-12 d-flex justify-content-center mb-4"},[_c('div',{staticStyle:{"max-width":"330px","width":"100%"}},[_c('input',{staticClass:"input-thm-light poppins-bold text-center full-width",attrs:{"type":"text","name":"folio","disabled":""},domProps:{"value":_vm.get_folio}})])]),_vm._m(3)])]),_vm._m(4)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"row h-100 d-flex justify-content-center align-items-start"},[_c('div',{staticClass:"col-12 mt-custom-text"},[_c('div',{staticClass:"mb-3 mb-sm-5"},[_c('div',{staticStyle:{"max-width":"700px"}},[_c('span',{staticClass:"hc--title text-white"},[_vm._v(" ¡"),_c('span',{staticClass:"text-warning"},[_vm._v("Felicidades")]),_vm._v(", estás a un paso de obtener tu finaciamiento! ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex justify-content-center mb-4 pt-3"},[_c('div',{staticStyle:{"max-width":"800px"}},[_c('span',{staticClass:"hc--description poppins-semibold text-white"},[_vm._v(" Te contactaremos a la brevedad para proporcionarte mayor información y resolver todas tus dudas ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-3"},[_c('span',{staticClass:"hc--description-sm poppins-semibold text-white"},[_vm._v(" Guarda este folio de preautorización ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex justify-content-center mb-2"},[_c('a',{attrs:{"href":"https://hircasa.com/","target":"_blank"}},[_c('button',{staticClass:"btn btn-warning poppins-semibold rounded-13 text-white"},[_vm._v(" Conoce más de HIR Casa ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex justify-center centrarTextoDama"},[_c('div',{staticClass:"col-10 mt-4 text-center"},[_c('span',{staticClass:"hc--title"},[_vm._v(" ¡Si eres mujer, pregunta a nuestros asesores acerca las promociones exclusivas para ti ")])])])
}]

export { render, staticRenderFns }